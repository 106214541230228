<template>
    <section class="blockElement bg-white pt-md-3 border-bottom position-sticky sndHeader"
        v-if="Object.keys(store.traderDetail).length">
        <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
        <div class="container-fluid">
            <div class="row align-items-center"
                v-if="store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length">
                <div class="col-6">
                    <div class="profileNews">
                        <div class="d-flex align-items-center stickyRight"
                            v-if="store.traderDetail.trader.stats.overallStats && Object.keys(store.traderDetail.trader.stats.overallStats).length">
                            <h5 class="regular me-3 mb-0">#{{ store.traderDetail.trader.stats.overallStats.zuluRank ||
                                '' }} <span class="secondary">
                                    {{ $t('leader_profile.zulu') }}</span>{{ $t('leader_profile.rank') }}</h5>
                            <div class="d-flex align-items-center"
                                v-if="store.traderDetail.trader.prevNextRank && Object.keys(store.traderDetail.trader.prevNextRank).length">
                                <a href="javascript:void(0)"
                                    @click="nextUser(store.traderDetail.trader.prevNextRank.prev.id)"
                                    v-if="store.traderDetail.trader.prevNextRank.prev">
                                    <vue-feather class="w-18" type="chevron-left"></vue-feather>
                                </a>
                                <a href="javascript:void(0)"
                                    @click="nextUser(store.traderDetail.trader.prevNextRank.next.id)"
                                    v-if="store.traderDetail.trader.prevNextRank.next">
                                    <vue-feather class="w-18" type="chevron-right"></vue-feather>
                                </a>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <span class="position-relative d-block my-md-3 side-avatar"
                                v-if="store.traderDetail.trader.stats.providerId && store.traderDetail.trader.stats.profile">
                                <!-- <span class="top-avtar" v-if="store.traderDetail.trader.stats.profile.countryName">
                                    <v-lazy-image
                                        width="100"
                                        :src="'/assets/images/icon/zuluicons/Flags/' + store.traderDetail.trader.stats.profile.countryIsoCode.toLowerCase() + '.png'"
                                        :alt="store.traderDetail.trader.stats.profile.countryName"
                                        :title="store.traderDetail.trader.stats.profile.countryName"
                                    />
                                </span> -->
                                <v-lazy-image class="rounded-circle border"
                                    :src="(!store.traderDetail.trader.stats.profile.photoApproved) ? (store.traderDetail.trader.stats.profile.customerAvatarUrl) ? store.traderDetail.trader.stats.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=provider&size=XL&id=' + store.traderDetail.trader.stats.providerId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=provider&size=XL&id=' + store.traderDetail.trader.stats.providerId + '&ignore=false'"
                                    :alt="store.traderDetail?.trader?.stats?.profile?.name || ''"
                                    :title="store.traderDetail?.trader?.stats?.profile?.name || ''" />
                                <!-- <span class="bottom-avtar orange" v-if="store.traderDetail.trader.stats.profile.ea == false"></span> -->
                                <!-- <span class="bottom-avtar green" v-else></span> -->
                            </span>
                            <div class="righrName ms-3">
                                <h5 class="mb-1"
                                    v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.name">
                                    {{ store.traderDetail.trader.stats.profile.name }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="d-flex align-items-center justify-content-md-end">
                        <a href="javascript:void(0)" class="ms-auto bold button zulu_btn rounded border-button"
                            @click="AddWatchList(store.traderDetail, 'WATCHLIST')"
                            :class="{ 'disabled': store.customerDetail?.readOnly }">
                            <vue-feather class="me-1" type="star" size="16"
                                :class="[{ 'filled': isFollowed(store.traderDetail.trader.stats.providerId) }]"></vue-feather>
                            {{ isFollowed(store.traderDetail.trader.stats.providerId) ? $t('watchlist_widget.added') :
                                $t('watchlist_widget.AddToWatchlist') }}
                        </a>
                        <!-- <a href="javascript:void(0)" class="bold button zulu_btn rounded border-button me-sm-3 me-0"><vue-feather class="me-1" size="16" type="message-square"></vue-feather> Message</a> -->
                        <!-- <div class="position-relative dropdown">
                            <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="f-17 fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item f-14" href="javascript:void(0)">Social</a></li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement mt-3 newFillTabs position-sticky">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-12 col-xl-8 col-lg-12">
                    <div class="addBG">
                        <ul id="tabs-nav" class="zuluTabs d-flex align-items-center px-0 mb-0">
                            <li :class="[{ 'active': tab == 1 }]"><a class="f-16" href="javascript:void(0)"
                                    @click="changeTab(1)">{{ $t('leader_profile.overview') }}</a></li>
                            <li :class="[{ 'active': tab == 2 }]"><a class="f-16" href="javascript:void(0)"
                                    @click="changeTab(2)">{{ $t('leader_profile.portfolio') }}</a></li>
                            <li :class="[{ 'active': tab == 3 }]"><a class="f-16" href="javascript:void(0)"
                                    @click="changeTab(3)">{{ $t('leader_profile.advancedStats') }}</a></li>
                            <li :class="[{ 'active': tab == 4 }]"><a class="f-16" href="javascript:void(0)"
                                    @click="changeTab(4)">{{ $t('leader_profile.copiers') }}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-xl-4 col-lg-12 d-none d-xl-block d-xxl-block"
                    v-if="store.traderDetail.trader && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length">
                    <div class="feed addBG">
                        <p class="bold f-22 mb-0">
                            {{ store.traderDetail.trader.stats.profile.name }}{{ $t('leader_profile.feedName') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="blockElement overViewInvestors space pt-3"
        v-if="Object.keys(store.traderDetail).length && store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xl-8">
                    <div class="bg-white boxed radius20 div-height">
                        <div class="row mb-3 viewTabOne" v-if="tab == 1">
                            <div class="col-12">
                                <div class="d-flex align-items-center justify-content-between mb-2">
                                    <div class="content">
                                        <div class="d-flex align-items-center flex-wrap stDes">
                                            <!-- <v-lazy-image width="13" class="d-block" src="/assets/images/idea.svg" alt="STRATEGY" title="North East Way EA." /> -->
                                            <h5 class="mb-md-0">{{ $t('leader_profile.strategyDescription') }}</h5>
                                            <div class="all-small-icon d-flex flex-wrap align-items-center mx-md-4">
                                                <span class="me-2"
                                                    v-if="COMMON.isCobrandedDomain() && store.traderDetail.trader.stats.profile?.brokerId">
                                                    <v-lazy-image class="d-block" src="/assets/images/logo.svg"
                                                        alt="ZuluTrade" title="ZuluTrade" />
                                                </span>
                                                <span class="me-2"
                                                    v-else-if="!COMMON.isCobrandedDomain() && store.traderDetail.trader.stats.profile?.brokerId">
                                                    <v-lazy-image class="d-block"
                                                        :src="static_vars.imagesURL + 'webservices/Image.ashx?type=broker&size=S&id=' + store.traderDetail.trader.stats.profile.brokerId + '&flavor=ZuluTrade'"
                                                        :alt="store.traderDetail.trader.stats.profile.brokerShortName"
                                                        :title="store.traderDetail.trader.stats.profile.brokerShortName" />
                                                </span>
                                                <span class="otherBroker" v-else>
                                                    {{ $t('leader_profile.otherBroker') }}</span>
                                                <span class="mx-1"
                                                    v-if="store.traderDetail.trader.stats.badges.hasLiveAccount">
                                                    <v-lazy-image class="d-block"
                                                        src="/assets/images/icon/small-icon/small-t-icon1.png"
                                                        :alt="$t('leader_profile.realAlt')"
                                                        :title="$t('leader_profile.realAlt')" />
                                                </span>
                                                <span class="mx-1" v-if="store.traderDetail.trader.stats.badges.ea">
                                                    <v-lazy-image class="d-block"
                                                        src="/assets/images/icon/small-icon/small-t-icon4.png"
                                                        :alt="$t('leader_profile.metaTraderAly')"
                                                        :title="$t('leader_profile.metaTraderAly')" />
                                                </span>
                                                <span class="mx-1 tooltipbutton"
                                                    v-if="store.traderDetail.trader.stats.badges.phoneVerified && store.traderDetail.trader.stats.badges.addressVerified && store.traderDetail.trader.stats.badges.identificationVerified">
                                                    <v-lazy-image class="d-block"
                                                        src="/assets/images/icon/small-icon/small-t-icon5.png"
                                                        :alt="$t('leader_profile.fullyVerified')"
                                                        :title="$t('leader_profile.fullyVerified')" />
                                                    <span class="tooltiptext">
                                                        <span
                                                            class="d-block border-bottom pb-2">{{ $t('leader_profile.fullyVerified') }}</span>
                                                        <span class="d-flex mt-2">
                                                            <v-lazy-image class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon5.png"
                                                                :alt="$t('leader_profile.mobilePhoneNumber')"
                                                                :title="$t('leader_profile.mobilePhoneNumber')" />
                                                            {{ $t('leader_profile.mobilePhoneNumber') }} -
                                                            <span>{{ $t('leader_profile.verified') }}</span>
                                                        </span>
                                                        <span class="d-flex mt-2">
                                                            <v-lazy-image class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon5.png"
                                                                :alt="$t('leader_profile.proofOfAddress')"
                                                                :title="$t('leader_profile.proofOfAddress')" />
                                                            {{ $t('leader_profile.proofOfAddress') }} -
                                                            <span>{{ $t('leader_profile.verified') }}</span>
                                                        </span>
                                                        <span class="d-flex mt-2">
                                                            <v-lazy-image class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon5.png"
                                                                :alt="$t('leader_profile.identificationDocument')"
                                                                :title="$t('leader_profile.identificationDocument')" />
                                                            {{ $t('leader_profile.identificationDocument') }} -
                                                            <span>{{ $t('leader_profile.verified') }}</span>
                                                        </span>
                                                    </span>
                                                </span>
                                                <span class="mx-1 tooltipbutton"
                                                    v-else-if="store.traderDetail.trader.stats.badges.phoneVerified || store.traderDetail.trader.stats.badges.addressVerified || store.traderDetail.trader.stats.badges.identificationVerified">
                                                    <v-lazy-image class="d-block"
                                                        src="/assets/images/icon/small-icon/small-t-icon6.png"
                                                        :alt="$t('leader_profile.partiallyVerified')"
                                                        :title="$t('leader_profile.partiallyVerified')" />
                                                    <span class="tooltiptext">
                                                        <span
                                                            class="d-block border-bottom pb-2">{{ $t('leader_profile.partiallyVerified') }}</span>
                                                        <span class="d-flex mt-2">
                                                            <v-lazy-image
                                                                v-if="store.traderDetail.trader.stats.badges.phoneVerified"
                                                                class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon5.png"
                                                                :alt="$t('leader_profile.mobilePhoneNumber')"
                                                                :title="$t('leader_profile.mobilePhoneNumber')" />
                                                            <v-lazy-image v-else class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon6.png"
                                                                :alt="$t('leader_profile.mobilePhoneNumber')"
                                                                :title="$t('leader_profile.mobilePhoneNumber')" />
                                                            {{ $t('leader_profile.mobilePhoneNumber') }} -
                                                            <span>{{
                                                                store.traderDetail.trader.stats.badges.phoneVerified ?
                                                                $t('leader_profile.verified') :
                                                                $t('leader_profile.pending') }}</span>
                                                        </span>
                                                        <span class="d-flex mt-2">
                                                            <v-lazy-image
                                                                v-if="store.traderDetail.trader.stats.badges.addressVerified"
                                                                class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon5.png"
                                                                :alt="$t('leader_profile.proofOfAddress')"
                                                                :title="$t('leader_profile.proofOfAddress')" />
                                                            <v-lazy-image v-else class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon6.png"
                                                                :alt="$t('leader_profile.proofOfAddress')"
                                                                :title="$t('leader_profile.proofOfAddress')" />
                                                            {{ $t('leader_profile.proofOfAddress') }} -
                                                            <span>{{
                                                                store.traderDetail.trader.stats.badges.addressVerified ?
                                                                $t('leader_profile.verified') :
                                                                $t('leader_profile.pending') }}</span>
                                                        </span>
                                                        <span class="d-flex mt-2">
                                                            <v-lazy-image
                                                                v-if="store.traderDetail.trader.stats.badges.identificationVerified"
                                                                class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon5.png"
                                                                :alt="$t('leader_profile.identificationDocument')"
                                                                :title="$t('leader_profile.identificationDocument')" />
                                                            <v-lazy-image v-else class="me-1"
                                                                src="/assets/images/icon/small-icon/small-t-icon6.png"
                                                                :alt="$t('leader_profile.identificationDocument')"
                                                                :title="$t('leader_profile.identificationDocument')" />
                                                            {{ $t('leader_profile.identificationDocument') }} -
                                                            <span>{{
                                                                store.traderDetail.trader.stats.badges.identificationVerified
                                                                ? $t('leader_profile.verified') :
                                                                $t('leader_profile.pending') }}</span>
                                                        </span>
                                                    </span>
                                                </span>
                                                <span class="mx-1"
                                                    v-if="store.traderDetail.trader.stats.badges.hasTradeHistoryImport">
                                                    <v-lazy-image class="d-block"
                                                        src="/assets/images/icon/small-icon/small-t-icon2.png"
                                                        :alt="`${$t('leader_profile.tradingHistoryFrom')} ${formatDate(store.traderDetail.trader.stats.badges.startDateOfImportedTradeHistory)} ${$t('leader_profile.to')} ${formatDate(store.traderDetail.trader.stats.badges.endDateOfImportedTradeHistory)} ${$t('leader_profile.importedZuluTrade')}`"
                                                        :title="`${$t('leader_profile.tradingHistoryFrom')} ${formatDate(store.traderDetail.trader.stats.badges.startDateOfImportedTradeHistory)} ${$t('leader_profile.to')} ${formatDate(store.traderDetail.trader.stats.badges.endDateOfImportedTradeHistory)} ${$t('leader_profile.importedZuluTrade')}`" />
                                                </span>
                                                <span class="mx-1"
                                                    v-if="store.traderDetail.trader.stats.badges.tradingCorrelatedCurrencies">
                                                    <v-lazy-image class="d-block"
                                                        src="/assets/images/icon/small-icon/small-t-icon7.png"
                                                        :alt="$t('leader_profile.correlatedCurrency')"
                                                        :title="$t('leader_profile.correlatedCurrency')" />
                                                </span>
                                                <span class="mx-1"
                                                    v-if="store.traderDetail.trader.stats.badges.tradingEconomicEvents">
                                                    <v-lazy-image class="d-block"
                                                        src="/assets/images/icon/small-icon/small-t-icon8.png"
                                                        :alt="$t('leader_profile.frequentlyTrading')"
                                                        :title="$t('leader_profile.frequentlyTrading')" />
                                                </span>
                                                <span class="ms-1 me-4"
                                                    v-if="store.traderDetail.trader.stats.badges.zuluVeteran">
                                                    <v-lazy-image class="d-block"
                                                        src="/assets/images/icon/small-icon/small-t-icon3.png"
                                                        :alt="$t('leader_profile.ZuluVeteran')"
                                                        :title="$t('leader_profile.ZuluVeteran')" />
                                                </span>
                                                <!-- <div class="d-flex align-items-center ms-2">
                                                    <p
                                                        class="f-12 mb-0 me-2"
                                                        v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.baseCurrencySymbol && store.traderDetail.trader.stats.overallStats && store.traderDetail.trader.stats.overallStats.balance"
                                                    >
                                                        BALANCE:<strong> {{ store.traderDetail.trader.stats.profile.baseCurrencySymbol }}{{ store.traderDetail.trader.stats.overallStats.balance }}</strong>
                                                    </p>
                                                    <p class="f-12 mb-0" v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.leverage">
                                                        LEVERAGE:<strong> {{ store.traderDetail.trader.stats.profile.leverage }}:1</strong>
                                                    </p>
                                                </div> -->
                                            </div>
                                            <a href="javascript:void(0)" class="d-flex align-items-center f-12 inactive"
                                                @click="translate()"> {{ translatedText ? $t('leader_profile.original') :
                                                $t('leader_profile.translate')}}</a>
                                        </div>
                                        <!-- <p>North East Way EA.</p> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-7">
                                <div class="consistently">
                                    <p class="mb-0 midgray d-inline"
                                        v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.strategyDescApproved == true && store.traderDetail.trader.stats.profile.strategyDesc && store.traderDetail.trader.stats.profile.translated && translatedText"
                                        v-html="brDec((show == 'less') ? ((store.traderDetail.trader.stats.profile.translated).slice(0, 150) + (store.traderDetail.trader.stats.profile.translated.length > 150 ? '...' : '')) : (store.traderDetail.trader.stats.profile.translated))">
                                    </p>
                                    <p class="mb-0 midgray d-inline"
                                        v-else-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.strategyDescApproved == true && store.traderDetail.trader.stats.profile.strategyDesc"
                                        v-html="brDec((show == 'less') ? ((store.traderDetail.trader.stats.profile.strategyDesc).slice(0, 150) + (store.traderDetail.trader.stats.profile.strategyDesc.length > 150 ? '...' : '')) : (store.traderDetail.trader.stats.profile.strategyDesc))">
                                    </p>
                                    <p class="mb-0 d-inline" v-else>{{ $t('leader_profile.noApprovedDescription') }}</p>

                                    <a v-if="store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.strategyDescApproved == true && store.traderDetail.trader.stats.profile.strategyDesc && store.traderDetail.trader.stats.profile.strategyDesc.length > 150"
                                        href="javascript:void(0)" class="f-14 medium menutext ms-2"
                                        @click="(show != 'less') ? show = 'less' : show = 'more'">{{ (show != 'less') ?
                                            $t('leader_profile.showLess') : $t('leader_profile.showMore') }}</a>
                                    <p v-if="store.traderDetail?.trader?.stats?.timeframeStats[dateDropSelected.key]?.warnings?.inactivity"
                                        :title="$t('leader_profile.inactiveTrader')"
                                        class="d-inline-flex bodyBG align-items-center inactive py-1 px-2 mb-0">
                                        <i class="gray me-1 fa fa-exclamation-circle" aria-hidden="true"></i>
                                        {{ $t('leader_profile.InactivityDetected') }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="d-flex align-items-center justify-content-lg-end mt-3 mt-md-0 mb-2">
                                    <div class="d-flex align-items-center me-md-3 me-2">
                                        <router-link
                                            :to="{ path: '/simulation', query: { 'id': store.traderDetail.trader.stats.providerId } }"
                                            href="javascript:void(0)"
                                            class="bold button secondary-btn zulu_btn rounded border-button me-md-3 me-1 simulateTour"
                                            :class="{ 'disabled': store.customerDetail?.readOnly }">{{ $t('leader_profile.simulate') }}</router-link>
                                        <a v-if="store.userSelectedAccount?.isLeader" href="javascript:void(0)"
                                            @click="cantCopy()"
                                            class="disabled bold button fillBtn zulu_btn copyStrategyTour">{{ $t('leader_profile.copyStrategy') }}</a>
                                        <a v-else href="javascript:void(0)"
                                            class="bold button fillBtn zulu_btn copyStrategyTour"
                                            :class="{ 'disabled': store.customerDetail?.readOnly }"
                                            @click="AddWatchList(store.traderDetail, 'COPY')">
                                            <!-- v-if="Object.keys(store.traderDetail).length && store.traderDetail.user && Object.keys(store.traderDetail.user).length && store.traderDetail.user.hasOwnProperty('following')" -->
                                            {{ copyORupdate() ? $t('leader_profile.update') :
                                            $t('leader_profile.copyStrategy')}}
                                        </a>
                                    </div>
                                    <!-- <ul class="d-flex opacity align-items-center mb-0">
                                            <li class="me-3">
                                                <a class="d-flex" href="javascript:void(0)"><vue-feather type="star"></vue-feather></a>
                                            </li>
                                            <li >
                                                <a class="d-flex more-vertical" href="javascript:void(0)"><vue-feather type="more-vertical"></vue-feather></a>
                                            </li>
                                    </ul> -->
                                    <div class="position-relative dropdown">
                                        <a href="javascript:void(0)" class="iconButton ms-1" type="button"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="f-17 fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton1">
                                            <li><a class="dropdown-item f-14" href="javascript:void(0)"
                                                    @click="sharePop = store.traderDetail.trader.stats.providerId">{{ $t('top_traders.share') }}</a>
                                            </li>
                                            <li><router-link
                                                    :to="{ path: '/compare', query: { p: store.traderDetail.trader.stats.profile.id } }"
                                                    class="dropdown-item f-14"
                                                    href="javascript:void(0)">{{ $t('top_traders.compare') }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <ul class="my-3 d-flex align-items-center equalSpace list-leftBar"
                                    v-if="Object.keys(store.traderDetail).length && store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length">
                                    <li class="ps-0">
                                        <h5 class="f-20 mb-0"
                                            :class="(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].annualizedRorBasedRoi) > 0.0) ? 'green' : 'red'">
                                            {{ (store.traderDetail.trader.stats.timeframeStats &&
                                                store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                            ?
                                            parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].annualizedRorBasedRoi).toFixed(2)
                                            : 0}}%</h5>
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="tooltipbutton right0">
                                                <span
                                                    class="inactive f-12 uppercase me-1">{{ $t('leader_profile.roi') }}</span>
                                                <span class="tooltiptext m-0" v-html="static_vars.roiTooltip"></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li :title="static_vars.riskTooltip">
                                        <h5 class="f-20 mb-0 regular"
                                            v-if="store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].hasOwnProperty('timeFrameRisk')"
                                            :class="store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk ? riskObj[store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk] : ''">
                                            {{ store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].timeFrameRisk }}
                                        </h5>
                                        <span class="inactive f-12 uppercase">{{ $t('leader_profile.risk') }}</span>
                                        <!-- <h5 class="f-20 mb-0 regular" :class="(parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney) > 0.0) ? 'green' : 'red'">${{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney).toFixed(2) : 0}}</h5>
                                        <span class="inactive f-12 uppercase">TOTAL PROFIT </span> -->
                                    </li>
                                    <li>
                                        <h5 class="f-20 mb-0"
                                            v-if="store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].winTrades">
                                            {{ store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].winTrades }}%
                                        </h5>
                                        <h5 class="f-20 mb-0" v-else>0%</h5>
                                        <span class="inactive f-12 uppercase">{{ $t('leader_profile.winRate') }}</span>
                                    </li>
                                    <li>
                                        <h5 class="f-20 mb-0"
                                            v-if="store.traderDetail.trader.stats.overallStats && store.traderDetail.trader.stats.overallStats.followers">
                                            {{ store.traderDetail.trader.stats.overallStats.followers }}</h5>
                                        <h5 class="f-20 mb-0" v-else>0</h5>
                                        <span class="inactive f-12 uppercase">{{ $t('leader_profile.copiersCAP') }}</span>
                                        <!-- <h5 class="f-20 mb-0" v-if="store.traderDetail.trader.stats.overallStats && store.traderDetail.trader.stats.overallStats.followers">{{store.traderDetail.trader.stats.overallStats.followers}}</h5>
                                        <span class="inactive f-12 uppercase">INVESTORS </span> -->
                                    </li>
                                    <!-- <li>
                                        <h5 class="f-20 mb-0" v-if="store.traderDetail.trader.stats.overallStats && store.traderDetail.trader.stats.overallStats.balance">{{(parseFloat(store.traderDetail.trader.stats.overallStats.balance).toLocaleString()).split(".")[0]}}.<sub>{{(store.traderDetail.trader.stats.overallStats.balance.toFixed(2)).split(".")[1]}}</sub></h5>
                                        <span class="inactive f-12 uppercase">BALANCE</span>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 customHeight">
                                <div class="rightTradeChart position-relative">
                                    <ul id="tabs-nav" class="zuluTabs d-flex align-items-center mb-3" v-if="tab == 3">
                                        <li :class="[{ 'active': charttab == 1 }]"><a class="pt-3 pb-3 f-16"
                                                href="javascript:void(0)"
                                                @click="changeChartTab(1)">{{ $t('leader_profile.performance') }}</a></li>
                                        <li :class="[{ 'active': charttab == 2 }]"><a class="pt-3 pb-3 f-16"
                                                href="javascript:void(0)"
                                                @click="changeChartTab(2)">{{ $t('leader_profile.trading') }}</a></li>
                                        <li :class="[{ 'active': charttab == 3 }]"><a class="pt-3 pb-3 f-16"
                                                href="javascript:void(0)"
                                                @click="changeChartTab(3)">{{ $t('leader_profile.drawDown') }}</a></li>
                                        <li v-if="!COMMON.isCobrandedDomain()" :class="[{ 'active': charttab == 4 }]"><a
                                                class="pt-3 pb-3 f-16" href="javascript:void(0)"
                                                @click="changeChartTab(4)">{{ $t('leader_profile.slippage') }}</a></li>
                                    </ul>
                                    <div id="advancedSearch"
                                        class="d-block d-sm-flex align-items-center justify-content-between"
                                        v-if="tab != 4">
                                        <ul id="tabs-nav" class="d-flex align-items-center mb-md-0" v-if="tab == 3">
                                            <li>
                                                <a class="toogleUP"
                                                    :class="[{ 'active': tradingprofitTab == store.traderDetail.trader.stats.profile.baseCurrencyName }]"
                                                    href="javascript:void(0)"
                                                    @click="Charttab(store.traderDetail.trader.stats.profile.baseCurrencyName);">
                                                    {{ store.traderDetail.trader.stats.profile.baseCurrencyName }}
                                                </a>
                                            </li>
                                            <li><a class="toogleUP" :class="[{ 'active': tradingprofitTab == 'Pips' }]"
                                                    href="javascript:void(0)" @click="Charttab('Pips');">Pips</a></li>
                                        </ul>
                                        <div :class="[{ 'pairTwo': tab == 2, 'pairOne': tab == 1 }]"
                                            class="position-relative advancedMenu currencypair"
                                            v-if="store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length && store.traderDetail.trader.stats.overallStats && store.traderDetail.trader.stats.overallStats.providerCurrencies">
                                            <a href="javascript:void(0)"
                                                class="selectDropdown bg-white d-flex align-items-center justify-content-between f-14"
                                                @click="currencyPair = !currencyPair">
                                                <span class="d-flex align-items-center"><i class="me-1 fa fa-money"
                                                        aria-hidden="true"></i>{{ selectedCurrency() }}</span> <i
                                                    class="fa fa-caret-down" aria-hidden="true"></i>
                                            </a>
                                            <ul class="dropdown_menu_animated week pb-2"
                                                :class="[{ 'show': currencyPair }]">
                                                <li class="searchDropdown">
                                                    <input type="search" placeholder="Filter"
                                                        class="form-control radiun-0 searchCountry" name=""
                                                        v-model="currencySearch" />
                                                </li>
                                                <li v-for="item, index in currencyList" :key="index"><a
                                                        :class="[{ 'active': currencyFilter[item] == item }]"
                                                        href="javascript:void(0)" @click="ClickCurrency(item)">{{ item
                                                        }}</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="bar-chart position-relative border-bottom pb-4 mb-3 chartTour"
                                        v-if="tab == 1">
                                        <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                                        <Profit ref="profit" />
                                    </div>
                                </div>
                                <Trading v-if="tab == 2" ref="trading" />
                                <AdvancedStats v-if="tab == 3" ref="advancedchart" />
                                <RealInvestor v-if="tab == 4" ref="realInvestor" />
                            </div>
                            <ul class="d-flex align-items-center justify-content-evenly"
                                v-if="(tab == 1) || (tab == 3) || (tab == 2 && (this.$refs?.trading?.tTab && this.$refs.trading.tTab == 6))">
                                <li v-for="list in dateDrop" :key="list.key">
                                    <a class="f-14 gray selectDay"
                                        @click.prevent="dateDropSelected = list; changeRoute()"
                                        :class="[{ 'active': dateDropSelected.value == list.value }]"
                                        href="javascript:void(0);">{{ list.value }}</a>
                                </li>
                            </ul>
                            <div class="col-12" v-if="tab == 1">
                                <div class="statisticsOverall">
                                    <p class="medium">{{ $t('leader_profile.staticsOverall') }} {{ dateDropSelected.value }}
                                    </p>
                                    <ul class="overallList d-flex align-items-center flex-wrap justify-content-center mb-0"
                                        v-if="store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length">
                                        <li>
                                            <span class="inactive f-12 uppercase">{{ $t('leader_profile.profit') }}</span>
                                            <!-- <h5 v-if="tradingprofitTab=='Pips'" class="f-20 regular" :class="(parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney) > 0.0) ? 'green' : 'red'">{{(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length) ? parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney).toFixed(2) : 0}} pips</h5> -->
                                            <h5 class="f-20 regular"
                                                :class="(store.traderDetail.trader.stats.timeframeStats && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] && parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney) > 0.0) ? 'green' : 'red'">
                                                {{ (store.traderDetail.trader.stats.timeframeStats &&
                                                    store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                                    Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length
                                                &&
                                                store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney
                                                > 0) ? '' :
                                                '-'}}{{ COMMON.getBaseCurrencySymbol() }}{{ (store.traderDetail.trader.stats.timeframeStats
                                                    && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                    &&
                                                    Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                parseFloat(Math.abs(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].totalProfitMoney)).toFixed(2)
                                                : 0}}</h5>
                                        </li>
                                        <li>
                                            <span class="inactive f-12 uppercase"
                                                :title="$t('leader_profile.closedTrader')">{{ $t('leader_profile.trades') }}</span>
                                            <h5 class="f-20 regular">{{ (store.traderDetail.trader.stats.timeframeStats
                                                && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                &&
                                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].trades
                                                : 0}}</h5>
                                        </li>
                                        <li>
                                            <span class="inactive f-12 uppercase"
                                                :title="$t('leader_profile.maximumNumber')">
                                                {{ $t('leader_profile.maxOpenTrades') }}
                                            </span>
                                            <h5 class="f-20 regular">{{ (store.traderDetail.trader.stats.timeframeStats
                                                && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                &&
                                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].maxOpenTrades
                                                : 0}}</h5>
                                        </li>
                                        <li v-if="$refs.profit?.tradingprofitTab == 'Pips'">
                                            <span class="inactive f-12 uppercase"
                                                :title="$t('leader_profile.avrRate')">{{ $t('leader_profile.avgPips') }}</span>
                                            <h5 class="f-20 regular">{{ (store.traderDetail.trader.stats.timeframeStats
                                                && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                &&
                                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].avgPipsPerTrade).toFixed(2)
                                                : 0}}</h5>
                                        </li>
                                        <li v-else>
                                            <span class="inactive f-12 uppercase"
                                                :title="$t('leader_profile.avrRate')">{{ $t('leader_profile.avgprofit') }}
                                                %</span>
                                            <h5 class="f-20 regular">{{ (store.traderDetail.trader.stats.timeframeStats
                                                && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                &&
                                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].profitPercentage).toFixed(2)
                                                : 0}}%</h5>
                                        </li>
                                    </ul>
                                    <ul
                                        class="overallList d-flex align-items-center flex-wrap justify-content-center mb-0">
                                        <li>
                                            <span class="inactive f-12 uppercase"
                                                :title="$t('leader_profile.winningTrades')">{{ $t('leader_profile.winningTradtext') }}</span>
                                            <h5 class="f-20 regular" :title="winningTrades()">
                                                <h5 class="f-20 regular">
                                                    ({{ (store.traderDetail.trader.stats.timeframeStats &&
                                                        store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                        &&
                                                        Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                    ?
                                                    store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].winTradesCount
                                                    : 0}})<sub>{{ (store.traderDetail.trader.stats.timeframeStats &&
                                                        store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                        &&
                                                        Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                        ?
                                                        parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].winTrades).toFixed(2)
                                                        : 0}}%</sub></h5>
                                            </h5>
                                        </li>
                                        <li>
                                            <span class="inactive f-12 uppercase"
                                                :title="$t('leader_profile.recommendedInvestment')">
                                                {{ $t('leader_profile.recommendedInvestmenttext') }} </span>

                                            <h5 class="f-20 regular">{{ (store.traderDetail.trader.stats.timeframeStats
                                                && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                &&
                                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].recommendedMinAmount.htmlSymbol
                                                : '$'}}{{ (store.traderDetail.trader.stats.timeframeStats &&
                                                    store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                                    Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].recommendedMinAmount.amount
                                                : 0}}</h5>
                                        </li>
                                        <li>
                                            <span class="inactive f-12 uppercase"
                                                :title="$t('leader_profile.greatestDiffrence')">{{ $t('leader_profile.maximumDradown') }}</span>
                                            <h5 v-if="$refs.profit?.tradingprofitTab == 'Pips'" class="f-20 regular">
                                                -{{ (store.traderDetail.trader.stats.timeframeStats &&
                                                    store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                                    Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].overallDrawDown).toFixed(2)
                                                : 0}} pips</h5>
                                            <h5 v-else class="f-20 regular">
                                                (${{ (store.traderDetail.trader.stats.timeframeStats &&
                                                    store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key] &&
                                                    Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].overallDrawDownMoney).toFixed(2)
                                                : 0}})<sub>{{ (store.traderDetail.trader.stats.timeframeStats &&
                                                    store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                    &&
                                                    Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                    ?
                                                    parseFloat(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].overallDrawDownMoneyPercent).toFixed(2)
                                                    : 0}}%</sub></h5>
                                        </li>
                                        <li>
                                            <span class="inactive f-12 uppercase"
                                                :title="$t('leader_profile.averageTrading')">{{ $t('leader_profile.avgTradeWeek') }}</span>
                                            <h5 class="f-20 regular">{{ (store.traderDetail.trader.stats.timeframeStats
                                                && store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]
                                                &&
                                                Object.keys(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key]).length)
                                                ?
                                                secondsToFurther(store.traderDetail.trader.stats.timeframeStats[dateDropSelected.key].avgTradeSeconds)
                                                : 0}}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-4 d-block d-xl-none">
                    <div class="feed addBG my-3 ">
                        <span
                            class="bold f-22 mb-0">{{ store.traderDetail.trader.stats.profile.name }}{{ $t('leader_profile.feedName') }}</span>
                    </div>
                </div>
                <div class="col-12 col-xl-4 socialFeedRight pl-lg-0" :class="[{ 'logged-feed': store.user.access_token }]"
                    :style="'height:' + divHeigh + 'px'">
                    <Social ref="social" />
                </div>
            </div>
        </div>
    </section>
    <Nodata v-else></Nodata>
    <sharePop :shareURL="sharePop" :close="() => sharePop = ''" v-if="sharePop" :shareType="'leaderProfile'"></sharePop>
    <loginPopup v-if="showLoginPopup"></loginPopup>
    <copyStrategy v-if="copyStrategy"></copyStrategy>
</template>
<script>
import $ from "jquery";
import Profit from "@/components/charts/trader-profit";
import Trading from "./trading/trading";
import Social from "@/views/trader/social/social.vue";
import RealInvestor from "@/views/trader/real-investors/real-investors.vue"
import AdvancedStats from "./advanced-stats/advance-charts";
import { myStore } from "@/store/pinia-store";
import moment from "moment";
import { NavigationFailureType, isNavigationFailure } from "vue-router";
import copyStrategy from "./copy-strategy/copy-strategy";
import sharePop from "@/views/social-feed/share-pop";
import { useToast } from "vue-toastification";
//import LeadersFeed from "@/store/leader-stomp";
export default {
    setup() {
        const store = myStore();
        const toast = useToast();
        return { store, toast };
    },
    data() {
        return {
            appMode: process.env.VUE_APP_MODE,
            tab: 1,
            currencyPair: false,
            datePeriod: false,
            dateDrop: [
                { key: 1, value: "1D" },
                { key: 3, value: "3D" },
                { key: 7, value: "7D" },
                { key: 30, value: "1M" },
                { key: 90, value: "3M" },
                { key: 180, value: "6M" },
                { key: 365, value: "1Y" },
                { key: 10000, value: "Overall" },
            ],
            dateDropSelected: { key: 10000, value: "Overall" },
            currencyFilter: {},
            currencySearch: "",
            followerInfo: {},
            searchTrder: "",
            show: "less",
            tradingprofitTab: "",
            charttab: 1,
            divHeigh: "",
            showLoginPopup: false,
            copyStrategy: false,
            sharePop: '',
            translatedText: false,
            riskObj: {
                1: 'blueBtn bg-white',
                2: 'greenBtn bg-white',
                3: 'yellowBtn bg-white',
                4: 'redBtn bg-white',
                5: 'pinkBtn bg-white',
            },
            steps: [
                {
                    target: '.simulateTour',
                    header: {
                        title: 'Simulate',
                    },
                    content: `Discover <strong>Vue Tour</strong>!`
                },
                {
                    target: '.chartTour',
                    header: {
                        title: 'Chart Mode',
                    },
                    params: {
                        placement: 'bottom'
                    },
                    content: 'An awesome plugin made with Vue.js!'
                },
                {
                    target: '.copyStrategyTour',
                    content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
                    params: {
                        placement: 'top'
                    }
                }
            ]
        };
    },
    components: {
        // RealInverstors
        Profit,
        Trading,
        Social,
        AdvancedStats,
        copyStrategy,
        sharePop,
        RealInvestor
    },
    computed: {
        currencyList() {
            if (
                this.store.traderDetail.trader.stats &&
                Object.keys(this.store.traderDetail.trader.stats).length &&
                this.store.traderDetail.trader.stats.overallStats &&
                this.store.traderDetail.trader.stats.overallStats.providerCurrencies
            ) {
                if (this.currencySearch) {
                    return this.store.traderDetail.trader.stats.overallStats.providerCurrencies.split(",").filter((item) => item.toLowerCase().includes(this.currencySearch.toLowerCase()));
                } else {
                    return this.store.traderDetail.trader.stats.overallStats.providerCurrencies.split(",");
                }
            } else {
                return [];
            }
        },
    },
    watch: {
        "$route.params.id"() {
            if (this.$route.params.id) {
                this.defaultTimeframeSelected();
                this.currencyFilter = {}
                this.calltraderDetail();
            }
        },
        "store.traderDetail"() {
            if (Object.keys(this.store.traderDetail).length > 0) {
                setTimeout(() => {
                    this.$refs.profit.tradingprofitTab = 'ROI';
                    this.callCurrencyPairsList();
                    this.$refs.social.generalSocialList();
                    this.store.callSocialTypeList({}, true, this.store.traderDetail.trader.stats.profile.zuluAccountId);
                }, 300);
            }
        },
        "store.socialTypeList"() {
            if (Object.keys(this.store.socialTypeList).length && this.store.socialTypeList.content && this.store.socialTypeList.content.length && this.store.socialTypeList.content[0].zulutradeEventId) {
                this.store.callSocialTypeCommentList({}, false, this.store.socialTypeList.content[0].zulutradeEventId);
            }
        },
        tab() {
            if (this.tab == 1) {
                setTimeout(() => {
                    this.$refs.profit.tradingprofitTab = 'ROI';
                    this.$refs.profit.getTradingProfitChartData();
                }, 500);
            } else if (this.tab == 3) {
                setTimeout(() => {
                    this.tradingprofitTab = this.store.traderDetail.trader.stats.profile.baseCurrencyName;
                }, 300);
            }
        },
    },
    methods: {
        winningTrades() {
            if (this.store.traderDetail?.trader?.stats?.currencyStats && this.store.traderDetail?.trader?.stats?.currencyStats.length) {
                return this.store.traderDetail.trader.stats.currencyStats.map((val) => {
                    return `${val.currencyName} : ${val.currencyWinPercent} (${val.totalCurrencyCount} trades)`
                })
            }
        },
        callWatchList() {
            this.store.getWatchList({}, false, '1');
        },
        AddWatchList(item, type) {
            if (!this.store.user.access_token) {
                this.showLoginPopup = true;
            } else {
                this.showLoginPopup = false;
                if (type == "WATCHLIST") {
                    if (this.isFollowed(item.trader.stats.providerId)) {
                        this.addWatchList(item, "DELETE");
                    } else {
                        this.addWatchList(item, "ADD");
                    }
                } else if (type == "COPY") {
                    this.copyStrategy = true;
                }
            }
        },
        addWatchList(item, type) {
            let json = {};
            if (type == 'ADD') {
                json['id'] = item.trader.stats.providerId
                json['type'] = 'LEADER'
                json['name'] = item.trader.stats.profile.name
                json['roi'] = 0
                json['chart'] = {}
                let data = this.store.leaderWatchList
                data.push(json)
                this.store.$patch({ 'leaderWatchList': data })
            } else if (type == 'DELETE') {
                let data = this.store.leaderWatchList.filter(i => parseInt(i.id) != parseInt(item.trader.stats.providerId))
                this.store.$patch({ 'leaderWatchList': data })
            }
            let payload = {
                item: item.trader.stats.providerId,
                type: 'LEADER'
            }
            this.store.addToWatchList(payload, false, this, type)
        },
        isFollowed(id) {
            if (this.store.leaderWatchList.length) {
                let data = this.store.leaderWatchList.map((i) => i.id);
                if (data.includes(parseInt(id))) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        changeChartTab(val) {
            this.charttab = val;
        },
        Charttab(type) {
            this.tradingprofitTab = type;
        },
        goToRoute(path) {
            if (path) {
                // this.$router.push(path)
                this.$router.push(path).then((failure) => {
                    if (isNavigationFailure(failure, NavigationFailureType.created)) {
                        console.log("failure", failure);
                    }
                });
            }
        },
        searchTraders() {
            if (this.searchTrder) {
                this.store.callFollowersSearchList({}, false, this.searchTrder);
            }
        },
        ClickCurrency(item) {
            if (this.currencyFilter[item]) {
                delete this.currencyFilter[item];
            } else {
                this.currencyFilter[item] = item;
            }
            if (this.tab == 1) {
                this.$refs.profit.getTradingProfitChartData();
            } else if (this.tab == 2) {
                setTimeout(() => {
                    if (this.$refs.trading.tTab == 6) {
                        this.$refs.trading.resetPage()
                        this.$refs.trading.pageListing();
                    } else {
                        this.$refs.trading.openPositionList();
                    }

                }, 300);
            }
        },
        selectedCurrency() {
            if (Object.values(this.currencyFilter).length) {
                return Object.values(this.currencyFilter).length + " currency";
            } else {
                return "Any currency pair";
            }
        },
        nextUser(userid) {
            this.tab = 1;
            this.show = 'less'
            if (userid) {
                let path = `/trader/${userid}/trading?t=10000&m=1`;
                window.location.href = path;
                this.searchTrder = "";
            }
        },
        changeTab(val) {
            this.tab = val;
        },
        formatDate(date) {
            if (date) {
                let d = moment(date);
                let tz = new Date().getTimezoneOffset();
                d.add(tz, "minutes");
                return moment(d).format("YYYY/MM/DD HH:mm");
            }
        },
        changeRoute() {
            this.$router.push({ path: this.$route.path, query: { t: this.dateDropSelected.key, m: 1 } });
        },
        getCopyStats(id) {
            this.store.getCopyStats({}, false, id);
        },
        calltraderDetail() {
            if (!this.$route.query.t) {
                this.changeRoute();
            }
            this.store.calltraderDetail({}, true, this.$route.params.id, this).then(() => {
                this.$refs.profit.tradingprofitTab = 'ROI';
                this.$refs.profit.getTradingProfitChartData();
                document.title = `ZuluTrade Trader ${this.store.traderDetail.trader.stats.profile.name} Trading Statistics & Performance`;
                document.querySelector('meta[name="description"]').setAttribute("content", `Explore the trading statistics and performance of ZuluTrade trader ${this.store.traderDetail.trader.stats.profile.name}. Discover their profit, drawdown, and trading history. `);
            });
        },
        calltoFiveFollowers() {
            this.store.getTopFiveFollowersList({}, false, this.$route.params.id);
        },
        callCurrencyPairsList() {
            if (
                this.store.traderDetail.trader &&
                Object.keys(this.store.traderDetail.trader).length &&
                this.store.traderDetail.trader.stats &&
                this.store.traderDetail.trader.stats.profile &&
                this.store.traderDetail.trader.stats.profile.feedGroupId
            ) {
                this.store.callCurrencyPairsList({}, false, this.store.traderDetail.trader.stats.profile.feedGroupId);
            }
        },
        brDec(text) {
            let br = text
            // text.replace(/[\r\n]/g, "<br>");
            return br;
        },
        translate() {
            if (this.translatedText) {
                this.translatedText = false
            } else {
                this.translatedText = true
                if (this.store.traderDetail.trader.stats.profile && this.store.traderDetail.trader.stats.profile.strategyDescApproved && this.store.traderDetail.trader.stats.profile.strategyDesc) {
                    this.store.socialTranslate({ "text": this.store.traderDetail.trader.stats.profile.strategyDesc, "to": "en" }, false, this.store.traderDetail.trader.stats.providerId, 'leaderProfile')
                }
            }
        },
        copyORupdate() {
            if (Object.keys(this.store.copyTraders).length && this.store.copyTraders.providers && this.store.copyTraders.providers.length) {
                if (Object.keys(this.store.userSelectedAccount).length) {
                    let brokerFound = this.store.copyTraders.providers.filter((i) => i.brokerAccountId == this.store.userSelectedAccount.brokerAccountId);
                    if (brokerFound?.length) {
                        let finalData = brokerFound.filter((proviID) => proviID.providerId == this.$route.params.id)
                        if (finalData?.length) { return true }
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        cantCopy() {
            this.toast.info(this.static_vars.leaderCantCopy);
        },
        secondsToFurther(val) {
            if (val) {
                val = parseInt(val)
                let duration = moment.duration(val, "seconds")
                let plural = 's'
                if (val > 2.628e+6) {
                    let num = Math.round(duration.asMonths())
                    return `${num} Month${num > 1 ? plural : ''}`
                } else if (val > 604800) {
                    let num = Math.round(duration.asWeeks())
                    return `${num} Week${num > 1 ? plural : ''}`
                } else if (val > 86400) {
                    let num = Math.round(duration.asDays())
                    return `${num} Day${num > 1 ? plural : ''}`
                } else if (val > 3600) {
                    let num = Math.round(duration.asHours())
                    return `${num} Hour${num > 1 ? plural : ''}`
                } else if (val > 60) {
                    let num = Math.round(duration.asMinutes())
                    return `${num} Minute${num > 1 ? plural : ''}`
                } else {
                    let num = Math.round(duration)
                    return `${num} Second${num > 1 ? plural : ''}`
                }
            }
            return 0
        },
        defaultTimeframeSelected() {
            if (this.$route.query.t) {
                this.dateDrop.forEach((val) => {
                    if (val.key == parseInt(this.$route.query.t)) {
                        this.dateDropSelected = val;
                    }
                });
            } else {
                this.dateDropSelected = { key: 10000, value: "Overall" }
            }
        }
    },
    mounted() {
        let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".dateperiod").length) {
                _that.datePeriod = false;
            }
            if (!$(event.target).closest(".currencypair").length) {
                _that.currencyPair = false;
            }
        });
        $(window).scroll(function () {
            var shrinkHeader = 60;
            var scroll = $(window).scrollTop();
            if (scroll >= shrinkHeader) {
                $('.sndHeader').addClass('bg-sticky');
            } else {
                $('.sndHeader').removeClass('bg-sticky');
            }
        });
        //
        // this.$tours['myTour'].start()
        // console.log('userSelect',this.store.userSelectedAccount)
    },
    updated() {
        this.divHeigh = $(".div-height").height() + 30;
    },
    created() {
        this.defaultTimeframeSelected();
        this.calltraderDetail();
    },
};
</script>
